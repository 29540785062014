import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LandingContainer from './LandingContainer';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';

const ExternalRouter: React.FunctionComponent = () => {
    React.useEffect(() => {
        const path = window.location.href.replace('https', 'loop');
        const packageName = 'com.invoqhealth.loophealth.patient';
        const playStoreUrl = 'market://details?id=' + packageName;
        const appId = '1495161232';
        const appStoreUrl = 'https://apps.apple.com/app/id' + appId;

        if (isMobile && isAndroid) {
            window.location.href = playStoreUrl;
        } else if (isMobile && isIOS) {
            setTimeout(() => (window.location.href = appStoreUrl), 1000);
            window.location.href = path;
        }
    }, []);

    return (
        <Routes>
            <Route path={'/'} element={<LandingContainer />} />;
            <Route path={'*'} element={<Navigate to={'/'} />} />
        </Routes>
    );
};

export default ExternalRouter;
