import React from 'react';
import ExternalRouter from './components/pages/router';

const App: React.FunctionComponent = () => {
    return (
        <div className="App">
            <ExternalRouter />
        </div>
    );
};

export default App;
