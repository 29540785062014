import React from 'react';
import { StyledContainer, StyledLogo } from './styles';
import { LoopLogo } from '../../../assets/img';
import { IHeader } from './types';

const Header: React.FunctionComponent<IHeader> = ({ goHome }) => {
    return (
        <StyledContainer>
            <StyledLogo src={LoopLogo} alt={'Loop Health Logo'} onClick={goHome} />
        </StyledContainer>
    );
};

export default Header;
