import React from 'react';
import {
    StyledContainer,
    StyledInfoContainer,
    StyledAlignContainer,
    StyledSubtitle,
    StyledImage,
    StyledMainContainer,
    StyledHeader,
    StyledQrCodeContainer,
    StyledIconContainer
} from './styles';
import { Header } from '../../containers';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@loophealth/loop-ui-web-library';
import { AppFrame, AndroidIcon, IOSIcon, QrCode } from '../../../assets/img';


const LandingContainer: React.FunctionComponent = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    };

    return (
        <StyledMainContainer>
            <StyledContainer>
                <StyledHeader>
                    <Header goHome={goHome} />
                </StyledHeader>
                <StyledAlignContainer>
                    <StyledInfoContainer>
                        <Typography variant="titleLarge" weight="regular" color="tertiary">
                            Download the{' '}
                            <Typography variant="titleLarge" weight="regular" color="green">
                                Loop
                            </Typography>
                            <br /> App today
                        </Typography>

                        <StyledSubtitle>
                            <Typography variant="title2" weight="regular" color="tertiary">
                                Access your policy cards and our panel <br />
                                of doctors by downloading <br /> the app today
                            </Typography>
                        </StyledSubtitle>
                        <StyledQrCodeContainer>
                            <QrCode/>
                            <StyledIconContainer>
                                <AndroidIcon />
                                <IOSIcon />
                            </StyledIconContainer>
                        </StyledQrCodeContainer>
                    </StyledInfoContainer>
                    <StyledImage src={AppFrame} />
                </StyledAlignContainer>
            </StyledContainer>
        </StyledMainContainer>
    );
};

export default LandingContainer;
